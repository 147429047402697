import React from 'react'
import {Flex,Text, Box,Heading} from '@chakra-ui/react'
import Link from '../components/gatsbylink'
import MeinSEO from '../components/SEO'


const Begleitprogramm = () =>{
  return(
    <Box p="3" width="96%" maxWidth="1100" height="100%" >
  <MeinSEO 
title="Begleitprogramm zum Theaterfestival"
description="Informationen zum Begleitprogramm 'Landjudentum'"
keywords="Landjudentum, Der heilige Werner, Ermordung der Juden im Rhein-Hunsrück-Kreis und Umgebung, Begleitprogramm zum Theaterfestival"
/><Flex flexDirection="column" justifyContent="center" p="3">
<Box my="3" flex="1 0">

<Heading variant="titel"> Programm Theaterfestival 2022 </Heading> 
{/* 
<iframe allowfullscreen="true" style="border:none;width:100%;height:500px;" src="//e.issuu.com/embed.html?d=theaterfest_2021_web&u=hasenoderbein.de"></iframe>   */}

<span style={{fontSize:'.9rem'}}><Link href="https://kulturnetz-oberes-mittelrheintal.org">©Grafik Katrin Gloggengiesser, K.O.M.</Link></span>

<Text>
Wir festspielen pandemie-gerecht <br/>
vom 1.–17. Juli 2022  <br/> 
in Bacharach &amp; Oberwesel.     
</Text>
<Link>&rarr; Plan Jüdisches Leben wird demnächst hier zum Download zur Verfügung gestellt.</Link>
</Box>

<Box my="3" pb="6" flex="3">

<Heading variant="titel"> Begleitender Ausstellungsparcours<br/> in Bacharach und Oberwesel</Heading> 

  <Text>
   
Begleitend zum Festival veranstaltet und dokumentiert das K.O.M. einen Ausstellungsparcours „Landjudentum am Mittelrhein" in Bacharach und Oberwesel.
Vom 18. Juni bis Ende August sind analoge und virtuelle Ausstellungen und Installationen in Bacharach und Oberwesel geplant, mit Leihgaben aus der 2020 im Museums Simmern gezeigten Ausstellung "Jüdisches Leben auf dem Lande im Hunsrück" sowie mit ergänzenden Fakten zur jüdischen Geschichte am Mittelrhein.
Die <strong>Galerie Markt1</strong> wird multimedial Zeitzeugen des 20. Jahrhunderts zu Wort kommen lassen sowie eine <strong>Retrospektive zum roten Fenster des Künstlers Karl-Martin Hartmann</strong>, das von 2007 bis 2010 in der Wernerkapelle Bacharach installiert war, ausstellen.
Weitere Veranstaltungen und Ausstellungen – insbesondere zum <strong>antisemitischen Wernerkult der Region</strong> – sind in Oberwesel und in Bacharacher Schaufenstern und Kirchen geplant.    
</Text>
<Link href="/pdf/Programm-2022.pdf">&rarr; Theaterprogamm 2022</Link>
</Box>


  </Flex>    
</Box>


  )
}
export default Begleitprogramm
